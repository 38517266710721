/** 修改手机号 */
<template>
  <section class="account-form">
    <el-form v-show="activeName=='one'" :model="formOne" :rules="$rules.common" ref="formOne">

      <div class="mb-30">
        <p class="phone-titlle mb-10">验证码将发送到手机{{userInfo.phone|encryptPhone}}</p>
        <p class="phone-desc">如果长时间未收到验证码，请检查是否将运营商拉黑</p>
      </div>

      <el-form-item prop="captcha">
        <el-input class="flex" v-model="formOne.captcha" placeholder="请输入验证码" prefix-icon="el-icon-message">
          <el-button class="send-code" type="primary" slot="append" :disabled="isDisabled" @click="getCode('formOne')">
            {{codeMsg}}
          </el-button>
        </el-input>
      </el-form-item>

      <el-form-item class="form-btns">
        <el-button class="theme-back" type="primary" :loading="isLoading" @click="next('formOne')">
          下一步</el-button>
      </el-form-item>
    </el-form>

    <el-form v-show="activeName=='two'" :model="formTwo" :rules="$rules.common" ref="formTwo">

      <el-form-item prop="phone">
        <el-input v-model="formTwo.phone" placeholder="请输入手机号码" prefix-icon="el-icon-mobile-phone"></el-input>
      </el-form-item>

      <el-form-item prop="captcha">
        <el-input class="flex" v-model="formTwo.captcha" placeholder="请输入验证码" prefix-icon="el-icon-message">
          <el-button class="send-code" type="primary" slot="append" :disabled="isDisabled" @click="getCode('formTwo')">
            {{codeMsg}}
          </el-button>
        </el-input>
      </el-form-item>

      <el-form-item class="form-btns">
        <el-button class="theme-back" type="primary" :loading="isLoading" @click="submit('formTwo')">
          确 定</el-button>
      </el-form-item>

    </el-form>
  </section>
</template>
<script>
import { sendcaptcha, verifycaptcha, updatephone } from '@/api/account'
import { mapGetters } from "vuex"
export default {
  name: 'UpdatePhone',
  data () {
    return {
      activeName: 'one',
      formOne: {
        captcha: '',
      },
      formTwo: {
        phone: '',
        captcha: '',
      },
      isLoading: false,
      isDisabled: false,
      codeMsg: '获取验证码',
    };
  },
  computed: {
    ...mapGetters([
      'userInfo',
    ])
  },
  methods: {
    async next (formName = 'form') {
      const validateList = []   // 定义空数组
      this.$refs[formName].validateField(["captcha"], valid => {
        // this.field  为要校验的部分rules数组
        validateList.push(valid)
      })
      if (validateList.every((item) => item === '')) {
        this.endTimer()
        let { code, msg } = await verifycaptcha(this.formOne)
        if (code == 200) {
          this.activeName = 'two'
        } else {
          this.$message.error(msg)
        }
      } else {
        return false
      }
    },
    // 修改手机号
    async submit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.formTwo.old_phone = this.userInfo.phone
          this.formTwo.old_captcha = this.formOne.captcha
          this.isLoading = true
          try {
            let old_userInfo = this.$store.getters.userInfo
            let { code, msg } = await updatephone(this.formTwo)
            if (code == 200) {
              this.$message.success(msg);
              old_userInfo.phone = this.formTwo.phone
              this.$store.dispatch('setUserInfo', old_userInfo)
              this.$refs[formName].resetFields()
              this.$router.push('/my/info')
            } else {
              this.$message.error(msg)
            }
          } catch (error) { }
          this.endTimer()
          this.isLoading = false
        } else {
          return false;
        }
      });
    },
    //  获取验证码
    async getCode (formName) {
      if (formName == 'formOne') {
        this.codeMsg = '加载中'
        this.countDown(60)
        try {
          let { code, msg } = await sendcaptcha(this.userInfo.phone, 'oldphone')
          if (code == 200) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
            this.endTimer()
          }
        } catch (error) {
          this.$message.error(error);
          this.endTimer()
        }
        return false
      }

      const validateList = []   // 定义空数组
      this.$refs[formName].validateField(["phone"], valid => {
        // this.field  为要校验的部分rules数组
        validateList.push(valid)
      })

      if (validateList.every((item) => item === '')) {
        this.codeMsg = '加载中'
        this.countDown(60)
        try {
          let { code, msg } = await sendcaptcha(this.formTwo.phone, 'newphone')
          if (code == 200) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
            this.endTimer()
          }
        } catch (error) {
          this.$message.error(error);
          this.endTimer()
        }
      }
    },
    // 开始倒计时
    countDown (num) {
      if (!this.timer) {
        this.count = num
        this.codeMsg = `${num}秒`
        this.isDisabled = true
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= num) {
            this.count--
            this.codeMsg = `${this.count}秒`
          } else {
            this.endTimer()
          }
        }, 1000)
      }
    },
    // 结束倒计时
    endTimer (msg) {
      this.isDisabled = false
      clearInterval(this.timer)
      this.timer = null
      this.codeMsg = msg || '重新发送'
    }
  },
  beforeDestroy () {
    this.endTimer()
  },
}
</script>
<style lang="scss" scoped>
.phone-desc {
  color: #aaaaaa;
  font-size: 14px;
}
</style>