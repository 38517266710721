/** 重置密码 */
<template>
  <section class="account-form">
    <h4 v-if="showA" class="account-form-header">重置密码</h4>
    <el-tabs class="account-form-header" v-model="activeName" :before-leave="beforeLeave">
      <el-tab-pane label="1.验证手机号" name="one"> </el-tab-pane>
      <el-tab-pane label="2.设置新密码" name="two"> </el-tab-pane>
      <div class="tabs-arrow" :style="{borderLeftColor:activeName=='one'?'#6BA1F3':'#E9EDF2'}"></div>
    </el-tabs>
    <el-form :model="form" :rules="$rules.common" ref="form">

      <section v-show="activeName=='one'">

        <el-form-item prop="phone">
          <el-input v-model="form.phone" placeholder="请输入手机号码" prefix-icon="el-icon-mobile-phone"></el-input>
        </el-form-item>

        <el-form-item prop="captcha">
          <el-input class="flex" v-model="form.captcha" placeholder="请输入验证码" prefix-icon="el-icon-message">
            <el-button class="send-code" type="primary" slot="append" :disabled="isDisabled" @click="getCode('form')">
              {{codeMsg}}
            </el-button>
          </el-input>
        </el-form-item>

        <el-form-item class="form-btns">
          <el-button class="theme-back" type="primary" :loading="isLoading" @click="next('form')">
            下一步</el-button>
        </el-form-item>
      </section>

      <section v-show="activeName=='two'">

        <el-form-item prop="password">
          <el-input type="password" v-model="form.password" placeholder="请输入新密码" autocomplete="off"
            prefix-icon="el-icon-lock"></el-input>
        </el-form-item>

        <el-form-item prop="password_confirmation" :rules="[{ validator: validatePasswords, trigger: 'blur' }]">
          <el-input type="password" v-model="form.password_confirmation" placeholder="请确认新密码" autocomplete="off"
            prefix-icon="el-icon-lock">
          </el-input>
        </el-form-item>

        <el-form-item class="form-btns">
          <el-button class="theme-back" type="primary" :loading="isLoading" @click="submit('form')">
            确 定</el-button>
        </el-form-item>
      </section>

      <div v-if="showA" class="account-form-footer">
        <router-link to="/account/login" replace>已有账号，立即登录</router-link>
      </div>
    </el-form>
  </section>
</template>
<script>
import { sendcaptcha, updatepassword } from '@/api/account'
export default {
  name: 'passBack',
  props: {
    showA: {
      typeof: Boolean,
      default: true
    }
  },
  data () {
    return {
      activeName: 'one',
      form: {
        phone: '',
        captcha: '',
        password: '',
        password_confirmation: '',
      },
      isLoading: false,
      isDisabled: false,
      codeMsg: '获取验证码',
      // 二次密码验证
      validatePasswords: (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.form.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      },
    };
  },
  watch: {
    $route (newVal) {
      this.endTimer()
    }
  },
  methods: {
    beforeLeave () {
      return this.next()
    },
    next (formName = 'form') {
      const validateList = []   // 定义空数组
      this.$refs[formName].validateField(["phone", "captcha"], valid => {
        // this.field  为要校验的部分rules数组
        validateList.push(valid)
      })
      if (validateList.every((item) => item === '')) {
        this.activeName = 'two'
        return true
      } else {
        return false
      }
    },
    // 重置密码
    async submit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isLoading = true
          try {
            let { data, code, msg } = await updatepassword(this.form)
            if (code == 200) {
              this.$message.success(msg);
              this.$refs.form.resetFields()
              this.$store.dispatch('setUserToken', '')
              this.$store.dispatch('setUserInfo', '')
              this.$router.replace('/account/login')
            } else {
              this.$message.error(msg)
            }
          } catch (error) { }
          this.endTimer()
          this.isLoading = false
        } else {
          return false;
        }
      });
    },
    //  获取验证码
    async getCode (formName) {
      const validateList = []   // 定义空数组
      this.$refs[formName].validateField(["phone"], valid => {
        // this.field  为要校验的部分rules数组
        validateList.push(valid)
      })

      if (validateList.every((item) => item === '')) {
        this.codeMsg = '加载中'
        this.countDown(60)
        try {
          let { code, msg } = await sendcaptcha(this.form.phone, 'uppassword')
          if (code == 200) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
            this.endTimer()
          }
        } catch (error) {
          this.$message.error(error);
          this.endTimer()
        }

      }
    },
    // 开始倒计时
    countDown (num) {
      if (!this.timer) {
        this.count = num
        this.codeMsg = `${num}秒`
        this.isDisabled = true
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= num) {
            this.count--
            this.codeMsg = `${this.count}秒`
          } else {
            this.endTimer()
          }
        }, 1000)
      }
    },
    // 结束倒计时
    endTimer (msg) {
      this.isDisabled = false
      clearInterval(this.timer)
      this.timer = null
      this.codeMsg = msg || '重新发送'
    }
  },
  beforeDestroy () {
    this.endTimer()
  },
}
</script>
<style lang="scss" scoped>
.account-form {
  /deep/.el-tabs {
    margin-top: 14px;
    position: relative;
    .el-tabs__nav-wrap {
      padding: 0;
      .el-tabs__nav {
        width: 100%;
        .el-tabs__active-bar {
          display: none;
        }
        .el-tabs__item {
          width: 50%;
          height: 34px;
          line-height: 34px;
          background-color: #e9edf2;
          color: #a1a3a4;
          padding-left: 0;
          padding-right: 0;
          font-weight: 400;
          &.is-active {
            background-color: #6ba1f3;
            color: #f4f6fa;
          }
          &#tab-two::before {
            content: " ";
            display: inline-block;
            -webkit-transform: rotate(314deg);
            -ms-transform: rotate(314deg);
            transform: rotate(314deg);
            height: 0.5rem;
            width: 0.5rem;
            border-width: 0 0 2px 2px;
            border-color: #d9d9d9;
            border-style: solid;
            position: absolute;
            top: 10px;
            right: 13px;
          }
        }
      }
    }
    .el-tabs__content {
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 99;
      .tabs-arrow {
        border-top: 17px solid transparent;
        border-left: 15px solid transparent;
        border-bottom: 17px solid transparent;
      }
    }
  }
}
</style>