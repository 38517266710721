/** 修改密码 */
<template>
  <section class="account-form">
    <el-form :model="form" :rules="$rules.common" ref="form">

      <el-form-item prop="old_password" :rules="[{ required: true, message: '请输入旧密码' }]">
        <el-input type="password" v-model="form.old_password" placeholder="请输入旧密码" autocomplete="off"
          prefix-icon="el-icon-lock" show-password></el-input>
      </el-form-item>

      <el-form-item prop="password">
        <el-input type="password" v-model="form.password" placeholder="请输入新密码" autocomplete="off"
          prefix-icon="el-icon-lock" show-password></el-input>
      </el-form-item>

      <el-form-item prop="password_confirmation" :rules="[{ validator: validatePasswords, trigger: 'blur' }]">
        <el-input type="password" v-model="form.password_confirmation" placeholder="确认新密码" autocomplete="off"
          prefix-icon="el-icon-lock" show-password> </el-input>
      </el-form-item>

      <el-form-item class="form-btns">
        <el-button class="theme-back" type="primary" :loading="isLoading" @click="submit('form')"> 确 认</el-button>
      </el-form-item>
      <div class="account-form-footer justify-between">
        <br>
        <router-link to="/my/account_settings/pass_back" replace>忘记密码？</router-link>
      </div>
    </el-form>

  </section>
</template>
<script>
import { updatepassword } from '@/api/account'
export default {
  name: 'Register',
  data () {
    return {
      activeName: 'first',
      form: {
        old_password: '',
        password: '',
        password_confirmation: '',
      },
      isLoading: false,
      isDisabled: false,
      codeMsg: '获取验证码',
      // 二次密码验证
      validatePasswords: (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.form.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      },
    };
  },
  watch: {
    $route (newVal) {
      this.endTimer()
    }
  },
  methods: {
    // 注册
    async submit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isLoading = true
          try {
            let { code, msg } = await updatepassword(this.form)
            if (code == 200) {
              this.$message.success(msg);
              this.$refs.form.resetFields()
              this.$store.dispatch('setUserToken', '')
              this.$store.dispatch('setUserInfo', '')
              this.$router.replace('/account/login')
            } else {
              this.$message.error(msg)
            }
          } catch (error) { }
          this.isLoading = false
        } else {
          return false;
        }
      });
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/ .account-form-header {
  .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
  }
}
</style>