/** 修改手机号 */
<template>
  <div class="account-settings">
    <el-breadcrumb v-if="$isPc" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item to="/my/info">个人中心</el-breadcrumb-item>
      <el-breadcrumb-item v-if="type=='update_phone'">修改手机号</el-breadcrumb-item>
      <el-breadcrumb-item v-if="type=='update_pass'">修改密码</el-breadcrumb-item>
      <el-breadcrumb-item v-if="type=='pass_back'" to="/my/account_settings/update_pass">修改密码</el-breadcrumb-item>
      <el-breadcrumb-item v-if="type=='pass_back'">重置密码</el-breadcrumb-item>
    </el-breadcrumb>
    <UpdatePhone v-if="type=='update_phone'" />
    <UpdatePass v-else-if="type=='update_pass'" />
    <PassBack v-else :showA="false" />
  </div>
</template>
<script>
import UpdatePhone from '@/views/Account/updatePhone.vue'
import UpdatePass from '@/views/Account/updatePass.vue'
import PassBack from '@/views/Account/passBack.vue'
export default {
  name: 'AccountSettings',
  components: {
    UpdatePhone,
    UpdatePass,
    PassBack,
  },
  data () {
    return {
      type: this.$route.params.type
    }
  },
  watch: {
    $route (newVal) {
      this.type = this.$route.params.type
    }
  },
}
</script>
<style lang="scss" scoped>
/deep/ .account-form {
  width: 60%;
  margin: 0 auto;
}
/deep/ .account-form-header {
  padding: 0 50px;
}
</style>